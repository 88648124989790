import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de copa, finament granulós, groguenc, de 3-4 cm de diàmetre. Inicialment amb el marge girat interiorment, després es va obrint i finalment es doblega cap a l’exterior. L’himeni, que està dins la copa, és de color violaci fosc molt brillant i amb el temps s’aclara i perd la brillantor. Per defora és grisenc, marró violaci o marró oliva. Les espores són el·líptiques, llises, amb dues gotes lipídiques, de 5-14 x 6,5-7,4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      